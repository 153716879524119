import React from "react"

const Clock = ({ stroke, width = "30", height = "30" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 32 32"
  >
    <g
      id="Icon_feather-clock"
      data-name="Icon feather-clock"
      transform="translate(1 1)"
    >
      <path
        id="Path_clock_outer"
        data-name="Path clock outer"
        d="M30,16A14,14,0,1,1,16,2,14,14,0,0,1,30,16Z"
        transform="translate(0 0)"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_clock_hand_hour"
        data-name="Path clock hand hour"
        d="M16,8v8l5.333,2.667"
        transform="translate(0 0)"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
)

export default Clock